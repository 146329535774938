import React, { Component } from 'react';
import { connect } from 'react-redux';


class Logger extends Component {
    render() {
        return (
            <div className="logger">{ this.props.log }</div>
        );
    }
}

function getLogs(state) {
    return JSON.stringify(state);
}

function mapStateToProps(state) {
    return {
        log: getLogs(state.logger.log),
    };
}

export default connect(mapStateToProps)(Logger);