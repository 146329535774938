import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLookBgImage, setLookBgLoaded } from "../store/settings/actions";
import {uploadImage, urlToFile} from "../services/_functions";
import { Button } from '@vkontakte/vkui';
import {svgAsPngUri} from "save-svg-as-png";


class BlockCustomizeImage extends Component {

    clickSelect = () => {
        let file = document.getElementById('bg-image-file');
        file.value = '';
        if (!/safari/i.test(navigator.userAgent)) {
            file.type = '';
            file.type = 'file';
        }
        file.click();
    };

    unloadImage = () => {
        this.props.dispatch(setLookBgImage(''));
        this.props.dispatch(setLookBgLoaded(false));
    };

    uploadImage = (e) => {
        const successFunc = (result) => {
            this.props.dispatch(setLookBgImage(result));
            this.props.dispatch(setLookBgLoaded(true));
        };

        const failFunc = () => {};

        this.props.dispatch(uploadImage(e, successFunc, failFunc, 'full'));
    };

    render() {
        let imgStyle = {};
        if (this.props.isBgLoaded) imgStyle = { backgroundImage: ('url(' + this.props.bgImage + ')'), backgroundSize: 'cover' };

        return (
            <React.Fragment>
                <div className="form-add-img">
                    <div className="form-add-img__name">Изображение</div>
                    <div className="form-add-img__box mb-15">
                        <div className="form-add-img__img" style={ imgStyle }/>
                        <div className="form-add-img__info">
                            <div className="form-add-img__text">Размер изображения не должен превышать 5 Мб</div>
                            <div className="group-box">
                                <Button level="secondary" onClick={ this.clickSelect }>Загрузить</Button>
                                { this.props.isBgLoaded && <Button level="secondary" className="btn btn-gray__del" onClick={ this.unloadImage }/> }
                                <input className="hide-it" type='file' id='bg-image-file' onChange={ (e) => this.uploadImage(e) } />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        bgImage: state.settings.look.bgImage,
        isBgLoaded: state.settings.look.isBgLoaded,
    };
};

export default connect(mapStateToProps)(BlockCustomizeImage);