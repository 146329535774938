import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChromePicker } from 'react-color';
import '../../css/color-picker.css';
import {
    setLookColorBg, setLookColorCode, setLookColorFontText, setLookColorText,
    setLookRealColors
} from "../store/settings/actions";
import {googleEvent, yandexEvent} from "../services/_functions";
import { disablePageScroll, enablePageScroll, getScrollState } from 'scroll-lock';


class BlockColorPicker extends Component {

    state = {
        height: window.document.body.offsetHeight,
        selected: 1,
        colorCode: this.props.colorCode,
        colorText: this.props.colorText,
        colorFontText: this.props.colorFontText,
        colorBg: this.props.colorBg,
    };

    changeColorInterval;
    changeColorGoogleTimeout;

    selectPanel = (num) => {
        this.setState({ selected: num });

        switch (num) {
            case 1:
                googleEvent('Edit', 'Color', 'Color_QR');
                yandexEvent('edit_color_color_qr');
                break;

            case 2:
                googleEvent('Edit', 'Color', 'Color_background');
                yandexEvent('edit_color_background');
                break;

            case 3:
                googleEvent('Edit', 'Color', 'Color_border');
                yandexEvent('edit_color_border');
                break;

            case 4:
                googleEvent('Edit', 'Color', 'Color_text');
                yandexEvent('edit_color_color_text');
                break;
        }
    };

    changeColor = (color, num) => {
        if (num === 1) this.setState({ colorCode: color.hex });
        if (num === 2) this.setState({ colorBg: color.hex });
        if (num === 3) this.setState({ colorText: color.hex });
        if (num === 4) this.setState({ colorFontText: color.hex });
        this.props.dispatch(setLookRealColors());

        if (getScrollState()) disablePageScroll();

        clearTimeout(this.changeColorGoogleTimeout);
        this.changeColorGoogleTimeout = setTimeout(() => {
            googleEvent('Edit', 'Color', 'Color_picker_tap');
            yandexEvent('edit_color_picker_tap');

        }, 2500);
    };

    accept = () => {
        this.props.dispatch(setLookColorCode(this.state.colorCode));
        this.props.dispatch(setLookColorBg(this.state.colorBg));
        this.props.dispatch(setLookColorText(this.state.colorText));
        this.props.dispatch(setLookColorFontText(this.state.colorFontText));
        this.props.dispatch(setLookRealColors());
    };

    componentWillMount = () => {
        this.changeColorInterval = setInterval(() => this.accept(), 333);

    };

    componentWillUnmount = () => {
        this.accept();
        clearInterval(this.changeColorInterval);
    };

    endColorChange = () => {
        enablePageScroll();
    };

    render() {
        let headers = [];
        let panels = [];
        if (this.props.isCode) {
            headers.push(<div key='cp-tit-1' className={ 'ColorPicker__title' + (this.state.selected === 1 ? ' ColorPicker__title--active': '') } onClick={ () => this.selectPanel(1) }><span>Код</span></div>);
            panels.push(<div key='cp-con-1' className={ "ColorPicker__content_panel" + (this.state.selected === 1 ? ' ColorPicker__content_panel--selected': '') } id="picker-1"><ChromePicker disableAlpha color={ this.state.colorCode } onChange={ (e) => this.changeColor(e, 1)  } onChangeComplete={ this.endColorChange }/></div>);
        }
        if (this.props.isBorder) {
            headers.push(<div key='cp-tit-2' className={'ColorPicker__title' + (this.state.selected === 2 ? ' ColorPicker__title--active': '') } onClick={ () => this.selectPanel(2) }><span>Фон</span></div>);
            panels.push(<div key='cp-con-2' className={ "ColorPicker__content_panel" + (this.state.selected === 2 ? ' ColorPicker__content_panel--selected': '') } id="picker-2"><ChromePicker disableAlpha color={ this.state.colorBg } onChange={ (e) => this.changeColor(e, 2)  } onChangeComplete={ this.endColorChange }/></div>);
        }
        if (this.props.isText || this.props.lookText !== '') {
            headers.push(<div key='cp-tit-3' className={'ColorPicker__title' + (this.state.selected === 3 ? ' ColorPicker__title--active': '') } onClick={ () => this.selectPanel(3) }><span>Рамка</span></div>);
            panels.push(<div key='cp-con-3' className={ "ColorPicker__content_panel" + (this.state.selected === 3 ? ' ColorPicker__content_panel--selected': '') } id="picker-3"><ChromePicker disableAlpha color={ this.state.colorText } onChange={ (e) => this.changeColor(e, 3)  } onChangeComplete={ this.endColorChange }/></div>);
        }
        if ((this.props.isText || this.props.lookText !== '') && this.props.selectedType === 2) {
            headers.push(<div key='cp-tit-4' className={'ColorPicker__title' + (this.state.selected === 4 ? ' ColorPicker__title--active': '') } onClick={ () => this.selectPanel(4) }><span>Текст</span></div>);
            panels.push(<div key='cp-con-4' className={ "ColorPicker__content_panel" + (this.state.selected === 4 ? ' ColorPicker__content_panel--selected': '') } id="picker-4"><ChromePicker disableAlpha color={ this.state.colorFontText } onChange={ (e) => this.changeColor(e, 4)  } onChangeComplete={ this.endColorChange }/></div>);
        }
        if (headers.length !== 0) {
            headers = <div className='ColorPicker__header'>{ headers }</div>;
            panels = <div className='ColorPicker__content'>{ panels }</div>;
        }
        if ((this.props.isCode && !this.props.isBorder && (!this.props.isText || this.props.lookText === '')) || this.props.selectedType === 1) {
            headers = '';
            panels = <div className='ColorPicker__content alone'><div className={ "ColorPicker__content_panel" + (this.state.selected === 1 ? ' ColorPicker__content_panel--selected  ': '') } id="picker-1"><ChromePicker disableAlpha color={ this.state.colorCode } onChange={ (e) => this.changeColor(e, 1)  } onChangeComplete={ this.endColorChange }/></div></div>
        }

        return (
            <div className={ 'ColorPicker' + (this.props.noBottomBorder ? ' no-bottom-border': '') }>
                { headers }
                { panels }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isCode: state.settings.look.colorPicker.isCode,
        isText: state.settings.look.colorPicker.isText,
        isBorder: state.settings.look.colorPicker.isBorder,
        lookText: state.settings.look.text,
        selectedType: state.settings.look.selectedType,
        colorCode: state.settings.look.colorPicker.colorCode,
        colorText: state.settings.look.colorPicker.colorText,
        colorFontText: state.settings.look.colorPicker.colorFontText,
        colorBg: state.settings.look.colorPicker.colorBg,
    };
};

export default connect(mapStateToProps)(BlockColorPicker);