import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLookCPIsText, setLookText } from "../store/settings/actions";
import { wordPad } from "../services/_functions";
import InputSelect from "../components/InputSelect";


class BlockCustomizeText extends Component {

    state = {
        charsLeft: 30,
    };

    recalcLeft = (left, text, isTextChangedByUser = true) => {
        this.setState({ charsLeft: left });
        this.props.dispatch(setLookText(text, isTextChangedByUser));
        if (text !== '') this.props.dispatch(setLookCPIsText(true));
        else this.props.dispatch(setLookCPIsText(false));
    };

    componentDidMount() {
        this.setState({ charsLeft: 30 - this.props.lookText.length });
    }

    render() {
        let leftTitle = 'Осталось ' + this.state.charsLeft + wordPad(this.state.charsLeft, ' символ', ' символа', ' символов');
        if  (this.state.charsLeft === 0) leftTitle = 'Достигнута максимальная длина текста';

        return (
            <React.Fragment>
                <div className="form-input">
                    <InputSelect
                        placeholder="Написать сообщение..."
                        options={[
                            'Сканируйте камерой VK',
                            'Откройте камерой VK',
                            'Узнайте с помощью камеры VK',
                            'Подписаться',
                            'Присоединиться',
                        ]}
                        maxlen={ 30 }
                        changeFunc={ this.recalcLeft }
                        value={ this.props.lookText }
                        withClear={ true }
                        isTop={ true }
                    />
                    { this.props.isCounterVisible && <span>{ leftTitle }</span> }
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        lookText: state.settings.look.text,
        isCounterVisible: state.settings.look.isCounterVisible,
    };
};

export default connect(mapStateToProps)(BlockCustomizeText);