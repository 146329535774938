import React, { Component } from 'react';
import { connect } from "react-redux";
import Header from "../components/Header";
import BlockLook from "../components/BlockLook";
import Footer from "../components/Footer";
import { Panel, PanelHeader, PanelHeaderContent } from '@vkontakte/vkui';
import BlockLink from "../components/BlockLink";
import BlockWifi from "../components/BlockWifi";
import BlockContact from "../components/BlockContact";
import BlockText from "../components/BlockText";
import BlockEvent from "../components/BlockEvent";
import BlockGeo from "../components/BlockGeo";
import {setStatusBar} from "../services/vk";


class PanelAll extends Component {

    title = '';
    block = '';

    componentDidMount() {
        document.body.classList.add('scroll-smooth');
        document.body.classList.add('no-scroll-yet');
        window.addEventListener("scroll", this.checkScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.checkScroll);
        document.body.classList.remove('scroll-smooth');
        document.body.classList.remove('no-scroll-yet');
    }

    checkScroll = () => {
        const scroll = document.scrollingElement.scrollTop;
        if (scroll > 40) document.body.classList.remove('no-scroll-yet');
        else document.body.classList.add('no-scroll-yet');
    };

    render() {
        switch (this.props.page) {
            case 'link':
                this.title = 'Ссылка';
                this.block = <BlockLink/>;
                break;

            case 'wifi':
                this.title = 'Настройки Wi-Fi';
                this.block = <BlockWifi/>;
                break;

            case 'contact':
                this.title = 'Визитка';
                this.block = <BlockContact/>;
                break;

            case 'text':
                this.title = 'Текст';
                this.block = <BlockText/>;
                break;

            case 'event':
                this.title = 'Событие';
                this.block = <BlockEvent/>;
                break;

            case 'geo':
                this.title = 'Геолокация';
                this.block = <BlockGeo/>;
                break;
        }

        return (
            <Panel id={ this.props.id } theme='gray'>
                <PanelHeader theme="alternate">
                    <PanelHeaderContent>
                        { this.title }
                    </PanelHeaderContent>
                </PanelHeader>
                <Header title={ this.title }/>
                <div className="container" id="full-height">
                    { this.block }
                    <BlockLook/>
                </div>
                <div className="fake-footer"/>
                <Footer mode={this.props.page} className="fixed-pos"/>
            </Panel>
        );
    }

}

function mapStateToProps(state) {
    return {
    };
}

export default connect(mapStateToProps)(PanelAll);