/**
 * Конвертация картинки в строку base64
 * @param {string} file
 * @returns {Promise<unknown>}
 */

const toDataURL = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default toDataURL;