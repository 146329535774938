import React, { Component } from 'react';
import { connect } from "react-redux";
import { goBack } from 'react-router-redux';
import { PanelHeader, PanelHeaderContent, HeaderButton, platform, Div, IOS, Panel } from '@vkontakte/vkui';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import {googleEvent, yandexEvent} from "../services/_functions";


class PanelAbout extends Component {

    goBack = () => {
        this.props.dispatch(goBack());
    };

    componentDidMount() {
        googleEvent('What_is_QR', 'What_is_QR');
        yandexEvent('what_is_qr');
    }

    render() {
        return (
            <Panel id={ this.props.id } className="complete" theme="white">
                <PanelHeader
                    theme="alternate"
                    left={
                        <HeaderButton onClick={ this.goBack } >
                            { platform() === IOS ? <Icon28ChevronBack /> : <Icon24Back /> }
                        </HeaderButton>
                    }
                >
                    <PanelHeaderContent>
                        О QR-кодах
                    </PanelHeaderContent>
                </PanelHeader>
                <Div>
                    <div className="Quote" style={{ paddingTop: 15 }}>
                        <div className="Quote__title">Что такое QR-код?</div>
                        <div className="Quote__text">
                            <p>QR-код — по сути, обычный штрих-код, только выглядит иначе. Как и штрих-коды на товарах, QR-код содержит информацию, но какую именно — Вы решаете сами. Это может быть Ваша страница ВКонтакте, сайт, приложение, контактные данные, геолокация, настройки Wi-Fi — всё, что нужно для Ваших задач. Любые материалы, которые Вы добавите в QR-код, Ваши клиенты смогут открыть и посмотреть парой касаний.</p>
                        </div>
                    </div>
                    <div className="Quote">
                        <div className="Quote__title">Как отсканировать QR-код?</div>
                        <div className="Quote__text">
                            <p>Чтобы получить информацию со штрихкода, продавцы используют специальный сканер. Вашим клиентам не нужны особые устройства — достаточно открыть камеру в мобильном приложении ВКонтакте и навести её на QR-код. Приложение распознает его и предложит посмотреть сведения, которые Вы добавили.</p>
                        </div>
                    </div>
                    <div className="Quote">
                        <div className="Quote__title">Как создать QR-код?</div>
                        <div className="Quote__text">
                            <p>Просто введите нужную ссылку или информацию в онлайн-генератор кодов ВКонтакте, выберите один из макетов — и скачайте готовый QR-код в удобном для Вас формате. Генерация QR-кода займёт меньше минуты, а использовать его можно для самых разных задач.</p>
                        </div>
                    </div>
                    <div className="Quote">
                        <div className="Quote__title">Как использовать QR-код?</div>
                        <div className="Quote__text">
                            <p>Применение QR-кода не ограничено никакими рамками — это полезный инструмент и для бизнеса, и для личных целей. Разместить его можно где угодно: на товаре, кассе, открытке, наружной рекламе, слайде в презентации, визитке, футболке и любых других носителях.</p>
                            <p>QR-коды, созданные в конструкторе ВКонтакте, ещё и отличный инструмент аналитики. На этом же сайте Вы сможете посмотреть различную статистику: сколько людей отсканировали QR-код, сколько перешли по ссылке, сколько добавили в телефон напоминание о событии или Ваши контактные данные. С помощью этих данных Вы легко проанализируете эффективность рекламы.</p>
                        </div>
                    </div>
                </Div>
            </Panel>
        );
    }

}

function mapStateToProps(state) {
    return {
        selectedMenu: state.globals.selectedMenu,
    };
}

export default connect(mapStateToProps)(PanelAbout);