import React, { Component } from 'react';
import { connect } from 'react-redux';
import {decrementHistory, setBlockChanged, setSelectedUrl} from "./js/store/globals/actions";
import ReactGA from 'react-ga';
import PanelMain from "./js/panels/PanelMain";
import PanelFeedback from "./js/panels/PanelFeedback";
import PanelComplete from "./js/panels/PanelComplete";
import {ConfigProvider, View} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
// import '../src/css/style.css';
import '../src/scss/style.scss'
import PanelCompleteReview from "./js/panels/PanelCompleteReview";
import Modals from "./js/components/Modals";
import PanelAll from "./js/panels/PanelAll";
import PanelCustomize from "./js/panels/PanelCustomize";
import {getUserInfo, initApp} from "./js/services/vk";
import {YMInitializer} from "react-yandex-metrika";
import PanelAbout from "./js/panels/PanelAbout";
import {push} from 'react-router-redux';
import {ROUTES_LIB} from "./js/services/_locals";


class App extends Component {

    //
    blockChangingTimer;

    componentWillMount = () => {
        this.props.dispatch(initApp());
        this.props.dispatch(getUserInfo());
        // const result = await getReview(this.props.vkId.id);
        // if (result.review === true) this.props.dispatch(setReviewed());
    };

    onGoBack = () => {
        if (this.props.history.length > 1) {
            const prevPanel = this.props.history[this.props.history.length - 2];
            const page = ROUTES_LIB.find((item) => item.panel === prevPanel).page;
            this.props.dispatch(push(page));
            this.props.dispatch(decrementHistory());
        }
    }

    render() {

        let panel = '';
        let selected = '';
        let history = this.props.activeModal === null ? this.props.history : undefined;

        document.body.classList.remove('no-border');

        switch (this.props.pageId) {
            case '':
                panel = 'panelMain';
                selected = this.props.pageId;
                document.body.classList.add('no-border');
                break;

            case 'link':
                panel = 'panelAll';
                selected = this.props.pageId;
                document.body.classList.add('no-border');
                break;

            case 'wifi':
                panel = 'panelAll';
                selected = this.props.pageId;
                document.body.classList.add('no-border');
                break;

            case 'contact':
                panel = 'panelAll';
                selected = this.props.pageId;
                document.body.classList.add('no-border');
                break;

            case 'text':
                panel = 'panelAll';
                selected = this.props.pageId;
                document.body.classList.add('no-border');
                break;

            case 'event':
                panel = 'panelAll';
                selected = this.props.pageId;
                document.body.classList.add('no-border');
                break;

            case 'geo':
                panel = 'panelAll';
                selected = this.props.pageId;
                document.body.classList.add('no-border');
                break;

            case 'complete':
                panel = 'panelComplete';
                selected = this.props.pageId;
                break;

            case 'complete-review':
                panel = 'panelCompleteReview';
                selected = this.props.pageId;
                break;

            case 'customize':
                panel = 'panelCustomize';
                selected = this.props.pageId;
                break;

            case 'feedback':
                panel = 'panelFeedback';
                selected = this.props.pageId;
                break;

            case 'info':
                panel = 'panelAbout';
                selected = this.props.pageId;
                break;

            default:
                panel = 'panelMain';
                selected = '';
                break;
        }

        if (selected !== this.props.selectedUrl) {
            ReactGA.pageview(`/${selected}`);
            this.props.dispatch(setSelectedUrl(selected));

            clearTimeout(this.blockChangingTimer);
            this.blockChangingTimer = setTimeout(() => this.props.dispatch(setBlockChanged()), 1000);
        }

        return (
            <React.Fragment>
                <YMInitializer accounts={[54876676]} />
                <ConfigProvider isWebView={true} webviewType="vkapps">
                    <View
                        activePanel={ panel }
                        popout={ this.props.popout }
                        modal={ <Modals/> }
                        onSwipeBack={() => this.onGoBack()}
                        history={ history }
                    >
                        <PanelMain id="panelMain"/>
                        <PanelAbout id="panelAbout"/>
                        <PanelAll id="panelAll" page={ selected }/>
                        <PanelCustomize id="panelCustomize"/>
                        <PanelComplete id="panelComplete"/>
                        <PanelFeedback id="panelFeedback"/>
                        <PanelCompleteReview id="panelCompleteReview"/>
                    </View>
                </ConfigProvider>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        smallHeader: state.globals.smallHeader,
        popout: state.globals.popout,
        selectedUrl: state.globals.selectedUrl,
        vkId: state.vk.vkUser,
        history: state.globals.history,
        activeModal: state.globals.activeModal,
    };
}

export default connect(mapStateToProps)(App);
