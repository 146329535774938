import * as types from './actionTypes';
import {push} from "react-router-redux";

export const setPage = (page) => (
    {
        type: types.SET_PAGE,
        page
    }
);

export const setModal = (modal) => (
    {
        type: types.SET_MODAL,
        modal
    }
);

export const setColorModal = (colorModal) => (
    {
        type: types.SET_COLOR_MODAL,
        colorModal
    }
);

export const setSelectedUrl = (selectedUrl) => (
    {
        type: types.SET_SELECTED_URL,
        selectedUrl
    }
);

export const setShowReviewResult = (isShowReviewResult) => (
    {
        type: types.SET_SHOW_REVIEW_RESULT,
        isShowReviewResult
    }
);

export const setSelectedMenu = (selectedMenu) => (
    {
        type: types.SET_SELECTED_MENU,
        selectedMenu
    }
);

export const setPopout = (popout) => (
    {
        type: types.SET_POPOUT,
        popout
    }
);

export const setActiveModal = (activeModal) => (dispatch, getState) => {
    if (activeModal !== null) {
        dispatch(push(getState().router.location.pathname + '?1'));
    }

    dispatch({
        type: types.SET_ACTIVE_MODAL,
        activeModal
    });
};

export const setBlockChanged = () => (
    {
        type: types.SET_BLOCK_CHANGED,
    }
);

export const setBlockChanging = () => (
    {
        type: types.SET_BLOCK_CHANGING,
    }
);

export const randQrKey = () => (
    {
        type: types.RAND_QR_KEY,
    }
);

export const setSaveAvailable = (saveAvailable) => (
    {
        type: types.SET_SAVE_AVAILABLE,
        saveAvailable,
    }
);

export const setHistory = (panel) => (
    {
        type: types.SET_HISTORY,
        panel,
    }
);

export const decrementHistory = () => (
    {
        type: types.DECREMENT_HISTORY,
    }
);

export const setReviewed = () => (
    {
        type: types.SET_REVIEWED,
    }
);