import React, { Component } from 'react';
import MenuItem from "../components/MenuItem";
import { push } from 'react-router-redux';
import { connect } from "react-redux";
import {HeaderButton, IOS, Panel, PanelHeader, PanelHeaderContent, platform} from '@vkontakte/vkui';
import Line from "../components/Line";
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline';
import Logger from "../components/Logger";


class PanelMain extends Component {

    openAbout = () => {
        this.props.dispatch(push('/info'));
    };

    render() {
        return (
            <Panel id={ this.props.id } theme='white'>
                <PanelHeader></PanelHeader>
                <div className="header">
                    <div className="header__title">Конструктор QR-кодов</div>
                    <div className="header__info">
                        Помогите клиентам быстро найти Вашу страницу в интернете. Используйте QR-коды — и делитесь ссылками на Ваши онлайн-ресурсы в офлайне.
                    </div>
                    <div className="header__bg"/>
                </div>
                <div className="Top-about">
                    <div className="Top-about-btn" onClick={ this.openAbout }><Icon28InfoOutline className="icon-about"/> О QR-кодах</div>
                </div>
                <Line/>
                <div className="main-menu">
                    <MenuItem
                        link='link'
                        title='Ссылка'
                        desc='Создайте QR-код со ссылкой на Вашу страницу ВКонтакте или любой другой сайт — клиентам не придётся вводить нужный адрес вручную.'
                    />
                    <MenuItem
                        link='wifi'
                        title='Настройки Wi-Fi'
                        desc='Добавьте в QR-код данные Вашего Wi-Fi, чтобы гости могли подключаться к сети без ввода пароля.'
                    />
                    <MenuItem
                        link='contact'
                        title='Визитка'
                        desc='Зашифруйте в QR-коде свои контактные данные: имя, должность, адрес, номер телефона. '
                    />
                    <MenuItem
                        link='text'
                        title='Текст'
                        desc='Разместите на товаре QR-код с любыми полезными сведениями: например, инструкцией, составом или информацией об акции.'
                    />
                    <MenuItem
                        link='event'
                        title='Событие'
                        desc='Благодаря QR-коду для мероприятия Ваши клиенты смогут добавить напоминание о событии в календарь телефона.'
                    />
                    <MenuItem
                        link='geo'
                        title='Геолокация'
                        desc='Укажите в QR-коде координаты Вашего заведения или места встречи — клиенты легко смогут проложить туда маршрут.'
                    />
                </div>
            </Panel>
        );
    }

}

function mapStateToProps(state) {
    return {
    };
}

export default connect(mapStateToProps)(PanelMain);
