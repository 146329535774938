import React, { Component } from 'react';
import '../../css/line.css';


export default class Line extends Component {
    render() {
        return (
            <div className="line"/>
        );
    }
}