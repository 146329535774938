import VKConnectReal from '@vkontakte/vkui-connect';
import VKConnectMock from '@vkontakte/vkui-connect-mock';
import * as VKActions from '../store/vk/actions';
import { store } from '../../index';
import {setLogger} from "../store/logger/actions";

const VKConnect = (process.env.NODE_ENV === 'production') ? VKConnectReal : VKConnectMock;
const API_VERSION = '5.100';


export const initApp = () => async(dispatch) => {
    return new Promise((resolve, reject) => {
        let handler = async(e) => {
            let vkEvent = e.detail;
            let type = vkEvent.type;
            let data = vkEvent.data;

            switch (type) {

                case 'VKWebAppUpdateConfig':
                    document.body.setAttribute('scheme', data.scheme);
                    if (e.detail.data.appearance === 'light') {
                        setStatusBarDark();
                    } else {
                        setStatusBarLight();
                    }
                    dispatch(VKActions.setInsets(data.insets));
                    break;

            }
        };
        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppInit', { 'no_toolbar': true });
    });
};


export const setStatusBarDark = () => {
    VKConnect.send("VKWebAppSetViewSettings", {"status_bar_style": "dark"});
};

export const setStatusBarLight = () => {
    VKConnect.send("VKWebAppSetViewSettings", {"status_bar_style": "light"});
};


export const getUserInfo = () => async (dispatch)  => {
    return new Promise((resolve, reject) => {
        let handler = async(e) => {
            e = e.detail;
            let found = false;

            switch(e.type) {
                case 'VKWebAppGetUserInfoResult':
                    found = true;
                    resolve(dispatch(VKActions.setVkUser(e.data)));
                    return e.data;

                case 'VKWebAppGetUserInfoFailed':
                    found = true;
                    reject(dispatch(VKActions.setVkUserFail()));
                    return '';

                default:
                    console.log(e);
            }
            if (found) VKConnect.unsubscribe(handler);
        };
        VKConnect.subscribe(handler);
        VKConnect.send("VKWebAppGetUserInfo", {});
    });
};


export const previewImage = (image) => async (dispatch)  => {
    return new Promise((resolve, reject) => {
        let handler = async(e) => {
            e = e.detail;
            let found = false;

            switch(e.type) {
                case 'VKWebAppShowImagesFailed':
                    found = true;
                    reject(false);
                    return '';

                default:
                    console.log(e);
            }
            if (found) VKConnect.unsubscribe(handler);
        };
        VKConnect.subscribe(handler);
        VKConnect.send("VKWebAppShowImages", {
            images: [image]
        });
    });
};
