import React, { Component } from 'react';
import { connect } from "react-redux";
import { UPDATE_TIME } from "../services/_locals";
import { setGeoPositionX, setGeoPositionY } from "../store/settings/actions";
import { YMaps, Map, Placemark, SearchControl } from 'react-yandex-maps';
import { Input, FormLayout } from '@vkontakte/vkui';
import {googleEvent, yandexEvent} from "../services/_functions";


class PanelGeo extends Component {

    state = {
        timeout: undefined,
        x: this.props.geoPositionX !== '' ? this.props.geoPositionX : 55.75,
        y: this.props.geoPositionY !== '' ? this.props.geoPositionY : 37.57,
        showPlacemark: false,
    };

    updateGoogleTimeoutX;
    updateGoogleTimeoutY;

    changeHandlerX = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);

        this.setState({ timeout: setTimeout(() => {
                this.props.dispatch(setGeoPositionX(val));
            }, UPDATE_TIME), showPlacemark: true, x: e.target.value });

        clearTimeout(this.updateGoogleTimeoutX);
        this.updateGoogleTimeoutX = setTimeout(() => {
            googleEvent('Edit', 'Geo', 'Data');
            yandexEvent('edit_geo_data');
        }, 2500);
    };

    changeHandlerY = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);

        this.setState({ timeout: setTimeout(() => {
                this.props.dispatch(setGeoPositionY(val));
            }, UPDATE_TIME), showPlacemark: true, y: e.target.value });

        clearTimeout(this.updateGoogleTimeoutY);
        this.updateGoogleTimeoutY = setTimeout(() => {
            googleEvent('Edit', 'Geo', 'Data');
            yandexEvent('edit_geo_data');
        }, 2500);
    };

    clickMap = (e) => {
        const coords = e.get('coords');
        this.setState({ x: coords[0], y: coords[1], showPlacemark: true });
        this.props.dispatch(setGeoPositionX(coords[0]));
        this.props.dispatch(setGeoPositionY(coords[1]));
    };

    onResultShow = () => {
        const coords = this.sc.getResult(0)._value.geometry._coordinates;
        this.setState({ showPlacemark: false, x: coords[0], y: coords[1] });
        this.props.dispatch(setGeoPositionX(coords[0]));
        this.props.dispatch(setGeoPositionY(coords[1]));
    };

    render() {
        return (
            <FormLayout className="white-layout">
                <Input top="Координаты" type="text" placeholder="Широта" onChange={ this.changeHandlerX } value={ this.state.x }  maxLength="64"/>
                <Input type="text" placeholder="Долгота" onChange={ this.changeHandlerY } value={ this.state.y }  maxLength="64"/>
                <YMaps
                    query={{
                        apikey: "f243589d-322a-4db9-8aad-8b83baa22659",
                    }}
                >
                    <div className="geo">
                        <Map height={ 240 } state={{ center: [this.state.x, this.state.y], zoom: 9 }} defaultState={{ center: [this.state.x, this.state.y], zoom: 9 }} onClick={ this.clickMap }>
                            <Placemark options={{ iconColor: '#ff0000',  visible: this.state.showPlacemark }} geometry={[this.state.x, this.state.y]} />
                            <SearchControl options={{ fitMaxWidth: true, maxWidth: [30, 72, 562] }} onResultShow={this.onResultShow} instanceRef={el => (this.sc = el)}/>
                        </Map>
                    </div>
                </YMaps>
            </FormLayout>
        );
    }

}

function mapStateToProps(state) {
    return {
        geoPositionX: state.settings.geo.position.x,
        geoPositionY: state.settings.geo.position.y,
    };
}

export default connect(mapStateToProps)(PanelGeo);