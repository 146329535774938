const doInputValidation = (mode) => (dispatch, getState) => {
    const settings = getState().settings;
    if (mode === 'link') {
        if (settings.link.url.trim() === '') {
            return 'Укажите URL-адрес';
        } else {
            return true;
        }
    } else if (mode === 'wifi') {
        if (settings.wifi.name.trim() === '') {
            return 'Укажите имя сети';
        } else {
            return true;
        }
    } else if (mode === 'contact') {
        if (settings.contact.firstName.trim() === '' && settings.contact.midName.trim() === '' && settings.contact.lastName.trim() === '') {
            return 'Укажите имя, фамилию или отчество';
        } else {
            return true;
        }
    } else if (mode === 'text') {
        if (settings.text.text.trim() === '') {
            return 'Введите текст';
        } else {
            return true;
        }
    } else if (mode === 'geo') {
        if (settings.geo.position.x.toString().trim() === '' || settings.geo.position.y.toString().trim() === '') {
            return 'Укажите координаты';
        } else {
            return true;
        }
    }
    return true;
};

export default doInputValidation;