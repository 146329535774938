import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    setContactCompany, setContactEmail, setContactFirstName, setContactLastName, setContactMidName, setContactPhone,
    setContactPosition, setContactUrl
} from "../store/settings/actions";
import { UPDATE_TIME } from "../services/_locals";
import { Input, FormLayout } from '@vkontakte/vkui';
import {googleEvent, yandexEvent} from "../services/_functions";


class BlockContact extends Component {

    state = {
        timeout: undefined,
    };

    updateGoogleTimeoutName;
    updateGoogleTimeoutLastName;
    updateGoogleTimeoutPosition;
    updateGoogleTimeoutPhone;
    updateGoogleTimeoutEmail;
    updateGoogleTimeoutVk;

    changeHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);

        switch (e.target.id) {
            case 'contact-first-name':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setContactFirstName(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutName);
                this.updateGoogleTimeoutName = setTimeout(() => {
                    googleEvent('Edit', 'Contact', 'Data_name');
                    yandexEvent('edit_contact_data_name');
                }, 2500);
                break;

            case 'contact-mid-name':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setContactMidName(val)), UPDATE_TIME) });
                break;

            case 'contact-last-name':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setContactLastName(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutLastName);
                this.updateGoogleTimeoutLastName = setTimeout(() => {
                    googleEvent('Edit', 'Contact', 'Data_surname');
                    yandexEvent('edit_contact_data_surname');
                }, 2500);
                break;

            case 'contact-position':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setContactPosition(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutPosition);
                this.updateGoogleTimeoutPosition = setTimeout(() => {
                    googleEvent('Edit', 'Contact', 'Data_job');
                    yandexEvent('edit_contact_data_job');
                }, 2500);
                break;

            case 'contact-company':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setContactCompany(val)), UPDATE_TIME) });
                break;

            case 'contact-phone':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setContactPhone(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutPhone);
                this.updateGoogleTimeoutPhone = setTimeout(() => {
                    googleEvent('Edit', 'Contact', 'Data_phone');
                    yandexEvent('edit_contact_data_phone');
                }, 2500);
                break;

            case 'contact-email':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setContactEmail(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutEmail);
                this.updateGoogleTimeoutEmail = setTimeout(() => {
                    googleEvent('Edit', 'Contact', 'Data_email');
                    yandexEvent('edit_contact_data_email');
                }, 2500);
                break;

            case 'contact-url':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setContactUrl(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutVk);
                this.updateGoogleTimeoutVk = setTimeout(() => {
                    googleEvent('Edit', 'Contact', 'Data_VK');
                    yandexEvent('edit_contact_data_VK');
                }, 2500);
                break;
        }
    };

    blurHandler = (e) => {
        clearTimeout(this.state.timeout);
        switch (e.target.id) {
            case 'contact-first-name':
                if (e.target.value !== this.props.contactFirstName) this.props.dispatch(setContactFirstName(e.target.value));
                break;

            case 'contact-mid-name':
                if (e.target.value !== this.props.contactMidName) this.props.dispatch(setContactMidName(e.target.value));
                break;

            case 'contact-last-name':
                if (e.target.value !== this.props.contactLastName) this.props.dispatch(setContactLastName(e.target.value));
                break;

            case 'contact-position':
                if (e.target.value !== this.props.contactPosition) this.props.dispatch(setContactPosition(e.target.value));
                break;

            case 'contact-company':
                if (e.target.value !== this.props.contactCompany) this.props.dispatch(setContactCompany(e.target.value));
                break;

            case 'contact-phone':
                if (e.target.value !== this.props.contactPhone) this.props.dispatch(setContactPhone(e.target.value));
                break;

            case 'contact-email':
                if (e.target.value !== this.props.contactEmail) this.props.dispatch(setContactEmail(e.target.value));
                break;

            case 'contact-url':
                if (e.target.value !== this.props.contactUrl) this.props.dispatch(setContactUrl(e.target.value));
                break;
        }
    };

    render() {
        return (
            <FormLayout className="white-layout">
                <Input top="Фамилия" type="text" placeholder="Введите фамилию" id='contact-last-name' onChange={ this.changeHandler } onBlur={ this.blurHandler } defaultValue={ this.props.contactLastName }/>
                <Input top="Имя" type="text" placeholder="Введите имя" id='contact-first-name' onChange={ this.changeHandler } onBlur={ this.blurHandler } defaultValue={ this.props.contactFirstName }/>
                <Input top="Отчество" type="text" placeholder="Введите отчество" id='contact-mid-name' onChange={ this.changeHandler } onBlur={ this.blurHandler } defaultValue={ this.props.contactMidName }/>
                <Input top="Должность" type="text" placeholder="Введите должность" id='contact-position' onChange={ this.changeHandler } onBlur={ this.blurHandler } defaultValue={ this.props.contactPosition }/>
                <Input top="Компания" type="text" placeholder="Введите компанию" id='contact-company' onChange={ this.changeHandler } onBlur={ this.blurHandler } defaultValue={ this.props.contactCompany }/>
                <Input top="Номер телефона" type="text" placeholder="Введите номер" id='contact-phone' onChange={ this.changeHandler } onBlur={ this.blurHandler } defaultValue={ this.props.contactPhone }/>
                <Input top="E-mail" type="text" placeholder="Введите e-mail" id='contact-email' onChange={ this.changeHandler } onBlur={ this.blurHandler } defaultValue={ this.props.contactEmail }/>
                <Input top="Ссылка на страницу ВКонтакте" type="text" placeholder="Введите ссылку" id='contact-url' onChange={ this.changeHandler } onBlur={ this.blurHandler } defaultValue={ this.props.contactUrl }/>
            </FormLayout>
        );
    }

}

function mapStateToProps(state) {
    return {
        contactFirstName: state.settings.contact.firstName,
        contactMidName: state.settings.contact.midName,
        contactLastName: state.settings.contact.lastName,
        contactPosition: state.settings.contact.position,
        contactCompany: state.settings.contact.company,
        contactPhone: state.settings.contact.phone,
        contactEmail: state.settings.contact.email,
        contactUrl: state.settings.contact.url,
    };
}

export default connect(mapStateToProps)(BlockContact);