import React, { Component } from 'react';
import { connect } from "react-redux";
import { push, goBack } from 'react-router-redux';
import QRCode from "../components/QRCode";
import BlockCustomizeType from "../components/BlockCustomizeType";
import BlockCustomizeStyle from "../components/BlockCustomizeStyle";
import BlockCustomizeText from "../components/BlockCustomizeText";
import BlockCustomizeImage from "../components/BlockCustomizeImage";
import BlockCustomizeIcon from "../components/BlockCustomizeIcon";
import BlockCustomizeAdvanced from "../components/BlockCustomizeAdvanced";
import {
    platform,
    IOS,
    Panel,
    PanelHeader,
    PanelHeaderContent,
    HeaderButton,
    Button
} from '@vkontakte/vkui';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import { calcHeight, googleEvent, urlToFile, urlToGoogleLink, yandexEvent } from "../services/_functions";
import { REFRESH_HEIGHT_TIME } from "../services/_locals";
import { svgAsPngUri } from 'save-svg-as-png';
import {saveLink, sendImage} from "../services/axios";
import { previewImage } from "../services/vk";


class PanelCustomize extends Component {

    state = {
        selectedTab: 0,
        isHide: false,
        isSaving: false,
    };

    tabArr = {
        1: [1, 3, 5],
        2: [1, 2, 3, 5, 6],
        3: [1, 2, 3, 5, 6],
        4: [1, 4, 5, 6],
    };

    saveImg = async() => {
        if (!this.state.isSaving) {
            this.setState({ isSaving: true });
            const qr = document.getElementById("qr-box").children[0].children[0].children[0];
            svgAsPngUri(qr, {}).then(uri => {
                setTimeout(() => {
                    svgAsPngUri(qr, {}).then(uri => {
                        urlToFile(uri, 'hello.png', 'image/png')
                            .then(async (file) => {
                                const res = await sendImage(file);
                                if (res['result'] === 'success') this.props.dispatch(previewImage(res['image']));
                                this.setState({ isSaving: false });

                                // if (!this.props.isReviewed) this.props.dispatch(push('/feedback'));
                                // else this.props.dispatch(push('/'));
                                // this.props.dispatch(setReviewed());
                                // setReview(this.props.vkId);
                                this.props.dispatch(push('/complete'));

                                googleEvent('Download', 'png', urlToGoogleLink(this.props.selectedUrl));
                                yandexEvent('download_png_' + this.props.selectedUrl);
                            });
                    });
                }, 500);
            });
            if (this.props.selectedMenu === 'link') saveLink(this.props.vkId, this.props.linkUrl);
        }
    };

    goBack = () => {
        this.props.dispatch(goBack());
    };

    handleSelectTypeChange = (num) => {
        this.setState({ selectedTab: num, isHide: false });
        setTimeout(() => calcHeight(), REFRESH_HEIGHT_TIME);
    };

    toggleHide = () => {
        this.setState(prevState => ({ isHide: !prevState.isHide }));
        setTimeout(() => calcHeight(), REFRESH_HEIGHT_TIME);
    };

    componentDidMount = () => {
        setTimeout(() => calcHeight(), REFRESH_HEIGHT_TIME);
    };

    render() {
        return (
            <Panel id={ this.props.id } theme='gray'>
                <PanelHeader
                    noShadow={ true }
                    left={
                        <HeaderButton onClick={ this.goBack } >
                            { platform() === IOS ? <Icon28ChevronBack /> : <Icon24Back /> }
                        </HeaderButton>
                    }
                >
                    <PanelHeaderContent>
                        Кастомизация
                    </PanelHeaderContent>
                </PanelHeader>
                <div id="qr-block" style={{ marginTop: 0 }}>
                    <QRCode/>
                    <div id="height-doodle"/>
                </div>
                <div className={ "customize-bottom-block" + (this.state.isHide ? " is-hide" : "" ) } id="customize-block">
                    <div className="tab-type">
                        <div className="tab-type__header">
                            <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 1 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(1)) }>Тип кода</div>
                            { (this.props.selectedType === 2 || this.props.selectedType === 3) && <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 2 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(2)) }>Текст</div> }
                            { (this.props.selectedType !== 4) && <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 3 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(3)) }>Стиль</div> }
                            { (this.props.selectedType === 4) && <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 4 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(4)) }>Изображение</div> }
                            <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 5 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(5)) }>Иконка</div>
                            { (this.props.selectedType !== 1) && <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 6 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(6)) }>Дополнительно</div> }
                        </div>
                        <div className="tab-type__body">
                            <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 1 ? ' tab-type__item--active' : '') }>
                                <BlockCustomizeType/>
                            </div>
                            <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 2 ? ' tab-type__item--active' : '') }>
                                <BlockCustomizeText/>
                            </div>
                            <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 3 ? ' tab-type__item--active' : '') }>
                                <BlockCustomizeStyle/>
                            </div>
                            <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 4 ? ' tab-type__item--active' : '') }>
                                <BlockCustomizeImage/>
                            </div>
                            <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 5 ? ' tab-type__item--active' : '') }>
                                <BlockCustomizeIcon/>
                            </div>
                            <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 6 ? ' tab-type__item--active' : '') }>
                                <BlockCustomizeAdvanced/>
                            </div>
                        </div>
                    </div>
                    <div className="footer-btn">
                        <Button className={ "hide-btn" + (this.state.isHide ? " is-hide" : "" ) } level="secondary" size="l" onClick={ this.toggleHide }/>
                        <Button level="secondary" size="l" onClick={ this.goBack }>Сохранить</Button>
                        <Button size="l" onClick={ this.saveImg }>Скачать</Button>
                    </div>
                </div>
            </Panel>
        );
    }

}

function mapStateToProps(state) {
    return {
        selectedMenu: state.globals.selectedMenu,
        selectedType: state.settings.look.selectedType,
        selectedUrl: state.globals.selectedUrl,
        vkId: state.vk.vkUser.id,
        isReviewed: state.globals.isReviewed,
        linkUrl: state.settings.link.url,
    };
}

export default connect(mapStateToProps)(PanelCustomize);
