import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    setAdvancedIconSelected,
    setLookBgImage,
    setLookBgLoaded, setLookCPIsBorder,
    setLookCPIsText,
    setLookSelectedType, setLookStyle3, setLookText
} from "../store/settings/actions";
import '../../css/color-picker.css';
import QRCode from "./QRCode";
import InputSelect from "./InputSelect";
import {googleEvent, uploadImage, wordPad, yandexEvent} from "../services/_functions";
import { setColorModal, setPopout } from "../store/globals/actions";
import PopoutUploadBackground from "./PopoutUploadBackground.js";
import BlockColorPicker from "./BlockColorPicker";
import { Button } from '@vkontakte/vkui';

class BlockLook extends Component {

    state = {
        charsLeft: 30,
    };

    handleSelectTypeChange = (selectedType) => {
        this.props.dispatch(setLookSelectedType(selectedType));
        // Тип 3 включаем бордер
        if (selectedType === 3) this.props.dispatch(setLookCPIsBorder(true));
        else this.props.dispatch(setLookCPIsBorder(false));
        // Тип 2 или 3 включаем по умолчанию рамку "откройте камерой ВК"
        if ((selectedType === 2 || selectedType === 3) && this.props.lookText === '') {
            if (!this.props.isTextChangedByUser) this.recalcLeft(11, 'Откройте камерой VK', false);
        }

        // Если юзер не выбирал кастомную иконку, то выбираем иконку в зависимости от стиля
        if (!this.props.isIconSelectedByUser) {
            if (selectedType === 1) {
                if (this.props.selectedStyle1 === 1) this.props.dispatch(setAdvancedIconSelected(3, false));
                if (this.props.selectedStyle1 === 2) this.props.dispatch(setAdvancedIconSelected(2, false));
            }
            if (selectedType === 2) {
                if (this.props.selectedStyle2 === 1) this.props.dispatch(setAdvancedIconSelected(3, false));
                if (this.props.selectedStyle2 === 2) this.props.dispatch(setAdvancedIconSelected(2, false));
            }
            if (selectedType === 3) {
                if (this.props.selectedStyle3 === 1) this.props.dispatch(setAdvancedIconSelected(3, false));
                if (this.props.selectedStyle3 === 2) this.props.dispatch(setAdvancedIconSelected(2, false));
            }
        }

        switch (selectedType) {
            case 1:
                googleEvent('Edit', 'Type', 'Plain_code');
                yandexEvent('edit_type_plain_code');
                break;

            case 2:
                googleEvent('Edit', 'Type', 'With_frame');
                yandexEvent('edit_type_with_frame');
                break;

            case 3:
                googleEvent('Edit', 'Type', 'With_background');
                yandexEvent('edit_type_with_background');
                break;

            case 4:
                googleEvent('Edit', 'Type', 'With_picture');
                yandexEvent('edit_type_with_picture');
                break;
        }
    };

    recalcLeft = (left, text, isTextChangedByUser = true) => {
        this.setState({ charsLeft: left });
        this.props.dispatch(setLookText(text, isTextChangedByUser));
        if (text !== '') this.props.dispatch(setLookCPIsText(true));
        else this.props.dispatch(setLookCPIsText(false));
    };

    handleOptionChangeStyle3 = (changeEvent) => {
        this.props.dispatch(setLookStyle3(+changeEvent.target.value));

        switch (+changeEvent.target.value) {
            case 1:
                this.props.dispatch(setAdvancedIconSelected(3, false));
                this.props.dispatch(setColorModal(false));
                googleEvent('Edit', 'Color', 'Black');
                yandexEvent('edit_color_black');
                break;

            case 2:
                this.props.dispatch(setAdvancedIconSelected(2, false));
                this.props.dispatch(setColorModal(false));
                googleEvent('Edit', 'Color', 'Blue');
                yandexEvent('edit_color_blue');
                break;

            case 3:
                this.props.dispatch(setColorModal(false));
                googleEvent('Edit', 'Color', 'Transparent');
                yandexEvent('edit_color_transparent');
                break;

            case 4:
                googleEvent('Edit', 'Color', 'Open_color_picker');
                yandexEvent('edit_color_open_color_picker');
                break;
        }
    };

    clickSelect = () => {
        let file = document.getElementById('bg-image-file');
        file.value = '';
        if (!/safari/i.test(navigator.userAgent)) {
            file.type = '';
            file.type = 'file';
        }
        file.click();
    };

    unloadImage = () => {
        this.props.dispatch(setLookBgImage(''));
        this.props.dispatch(setLookBgLoaded(false));
    };

    uploadImage = (e) => {
        const successFunc = (result) => {
            this.props.dispatch(setLookBgImage(result));
            this.props.dispatch(setLookBgLoaded(true));
        };

        const failFunc = () => {};

        this.props.dispatch(uploadImage(e, successFunc, failFunc, 'picture'));
    };

    openUploadBgModal = () => {
        this.props.dispatch(setPopout(<PopoutUploadBackground/>));
        googleEvent('Edit', 'Color', 'Picture');
        yandexEvent('edit_color_picture');
    };

    openColorMultiple = () => {
        this.props.dispatch(setColorModal(true));
    };

    componentDidMount() {
        this.setState({ charsLeft: 30 - this.props.lookText.length });
    }

    render() {
        let leftTitle = 'Осталось ' + this.state.charsLeft + wordPad(this.state.charsLeft, ' символ', ' символа', ' символов');
        if  (this.state.charsLeft === 0) leftTitle = 'Достигнута максимальная длина текста';

        let imgStyle = {};
        if (this.props.isBgLoaded) imgStyle = { backgroundImage: ('url(' + this.props.bgImage + ')'), backgroundSize: 'cover' };

        return (
            <div className="white-block">
                <div className="white-block__inner pad-b-0">
                    <div className="tab-second">
                        <div className="tab-second__header">
                            <div className={ "tab-second__title" + (this.props.selectedType === 1 ? ' tab-second__title--active' : '') } onClick={ () => this.handleSelectTypeChange(1) }><span>Только код</span></div>
                            <div className={ "tab-second__title" + (this.props.selectedType === 2 ? ' tab-second__title--active' : '') } onClick={ () => this.handleSelectTypeChange(2) }><span>С рамкой</span></div>
                            <div className={ "tab-second__title" + (this.props.selectedType === 3 ? ' tab-second__title--active' : '') } onClick={ () => this.handleSelectTypeChange(3) }><span>С фоном</span></div>
                            <div className={ "tab-second__title" + (this.props.selectedType === 4 ? ' tab-second__title--active' : '') } onClick={ () => this.handleSelectTypeChange(4) }><span>Карточка</span></div>
                        </div>
                        <div className="tab-second__body">
                            <div className={ "tab-second__item" + (this.props.selectedType === 1 ? ' tab-second__item--active' : '') }/>
                            <div className={ "tab-second__item" + (this.props.selectedType === 2 ? ' tab-second__item--active' : '') }>
                                <div className="form-input mb-15 mt-15">
                                    <div className="form-input__name">Текст</div>
                                    <InputSelect
                                        placeholder="Написать сообщение..."
                                        options={[
                                            'Сканируйте камерой VK',
                                            'Откройте камерой VK',
                                            'Узнайте с помощью камеры VK',
                                            'Подписаться',
                                            'Присоединиться',
                                        ]}
                                        maxlen={ 30 }
                                        changeFunc={ this.recalcLeft }
                                        value={ this.props.lookText }
                                        withClear={ true }
                                    />
                                    { this.props.isCounterVisible && <span>{ leftTitle }</span> }
                                </div>
                            </div>
                            <div className={ "tab-second__item" + (this.props.selectedType === 3 ? ' tab-second__item--active' : '') }>
                                <div className="group-form">
                                    <div className="group-form__name">Фон</div>
                                    <div className="group-form__radio">
                                        <div className="form-radio-color color-black">
                                            <input
                                                type="radio"
                                                id="radio-style-1"
                                                name="radio-box-style3"
                                                checked={ this.props.selectedStyle3 === 1 }
                                                value="1"
                                                onChange={ this.handleOptionChangeStyle3 }
                                            />
                                            <label htmlFor="radio-style-1"/>
                                        </div>
                                        <div className="form-radio-color color-blue">
                                            <input
                                                type="radio"
                                                id="radio-style-2"
                                                name="radio-box-style3"
                                                checked={ this.props.selectedStyle3 === 2 }
                                                value="2"
                                                onChange={ this.handleOptionChangeStyle3 }
                                            />
                                            <label htmlFor="radio-style-2"/>
                                        </div>
                                        <div className="form-radio-color color-transparent">
                                            <input
                                                type="radio"
                                                id="radio-style-3"
                                                name="radio-box-style3"
                                                checked={ this.props.selectedStyle3 === 3 }
                                                value="3"
                                                onChange={ this.handleOptionChangeStyle3 }
                                            />
                                            <label htmlFor="radio-style-3"/>
                                        </div>
                                        <div className="form-radio-color color-gradient">
                                            <input
                                                type="radio"
                                                id="radio-style-4"
                                                name="radio-box-style3"
                                                checked={ this.props.selectedStyle3 === 4 }
                                                value="4"
                                                onChange={ this.handleOptionChangeStyle3 }
                                                onClick={ () => this.openColorMultiple('radio-style-4') }
                                            />
                                            <label htmlFor="radio-style-4">
                                                <span className="form-radio-color__option"/>
                                            </label>
                                        </div>
                                        <div className="form-radio-color">
                                            <div className="form-radio-color_color-photo" onClick={ this.openUploadBgModal }/>
                                        </div>
                                    </div>
                                    { this.props.colorModal && <BlockColorPicker/> }
                                </div>
                                <div className="form-input mb-15">
                                    <InputSelect
                                        placeholder="Написать сообщение..."
                                        options={[
                                            'Сканируйте камерой VK',
                                            'Откройте камерой VK',
                                            'Узнайте с помощью камеры VK',
                                            'Подписаться',
                                            'Присоединиться',
                                        ]}
                                        maxlen={ 30 }
                                        changeFunc={ this.recalcLeft }
                                        value={ this.props.lookText }
                                        withClear={ true }
                                    />
                                    <span>{ leftTitle }</span>
                                </div>
                            </div>
                            <div className={ "tab-second__item" + (this.props.selectedType === 4 ? ' tab-second__item--active' : '') }>
                                <div className="form-add-img">
                                    <div className="form-add-img__name">Изображение</div>
                                    <div className="form-add-img__box mb-15">
                                        <div className="form-add-img__img" style={ imgStyle }/>
                                        <div className="form-add-img__info">
                                            <div className="form-add-img__text">Размер изображения не должен превышать 5 Мб</div>
                                            <div className="group-box">
                                                <Button level="secondary" onClick={ this.clickSelect }>Загрузить</Button>
                                                { this.props.isBgLoaded && <Button level="secondary" className="btn btn-gray__del" onClick={ this.unloadImage }/> }
                                                <input className="hide-it" type='file' id='bg-image-file' onChange={ (e) => this.uploadImage(e) } />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { !this.props.isBlockChanging && <QRCode/> }
                    { this.props.isBlockChanging && <div className="qr-doodle"/> }
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        lookText: state.settings.look.text,
        selectedType: state.settings.look.selectedType,
        selectedStyle1: state.settings.look.selectedStyle1,
        selectedStyle2: state.settings.look.selectedStyle2,
        selectedStyle3: state.settings.look.selectedStyle3,
        bgImage: state.settings.look.bgImage,
        isBgLoaded: state.settings.look.isBgLoaded,
        isIconSelectedByUser: state.settings.advanced.isIconSelectedByUser,
        isTextChangedByUser: state.settings.look.isTextChangedByUser,
        linkUrl: state.settings.link.url,
        lookType: state.settings.look.selectedType,
        colorModal: state.globals.colorModal,
        isBlockChanging: state.globals.isBlockChanging,
        isCounterVisible: state.settings.look.isCounterVisible,
    };
};

export default connect(mapStateToProps)(BlockLook);