import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/cell.css';
import Icon16Chevron from '@vkontakte/icons/dist/16/chevron';

class BlockCell extends Component {
    render() {
        return (
           <div className="Retargeting" onClick={ this.props.clickHandler }>
               <div className={"Retargeting--image " + this.props.image + "-ico"}/>
               <div className="Retargeting--title">{this.props.title}</div>
               <div className="Retargeting--icon"><Icon16Chevron fill="#c4c8cc" height={18} width={18}/></div>
           </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(BlockCell);
