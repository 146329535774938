import React, { Component } from 'react';
import { connect } from "react-redux";
import {UPDATE_TIME} from "../services/_locals";
import {setTextText} from "../store/settings/actions";
import { Textarea, FormLayout, FormLayoutGroup } from '@vkontakte/vkui';
import {googleEvent, yandexEvent} from "../services/_functions";


class BlockText extends Component {

    state = {
        timeout: undefined,
    };

    updateGoogleTimeout;

    changeHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);

        this.setState({ timeout: setTimeout(() => this.props.dispatch(setTextText(val)), UPDATE_TIME) });

        clearTimeout(this.updateGoogleTimeout);
        this.updateGoogleTimeout = setTimeout(() => {
            googleEvent('Edit', 'Text', 'Data');
            yandexEvent('edit_text_data');
        }, 2500);
    };

    render() {
        return (
            <FormLayout className="white-layout">
                <FormLayoutGroup top="Текст">
                    <Textarea name="" id="" cols="" rows="4" placeholder="Введите текст" maxLength={ 110 } onChange={ this.changeHandler } defaultValue={ this.props.text }/>
                </FormLayoutGroup>
            </FormLayout>
        );
    }

}

function mapStateToProps(state) {
    return {
        text: state.settings.text.text,
    };
}

export default connect(mapStateToProps)(BlockText);