import React, { Component } from 'react';
import { connect } from "react-redux";
import { addReview } from "../services/axios";
import { push, goBack } from 'react-router-redux';
import { FixedLayout, Div, Textarea, FormLayout, FormLayoutGroup, Radio, Button, platform, IOS, Panel, PanelHeader, PanelHeaderContent, HeaderButton  } from '@vkontakte/vkui';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';


class PanelFeedback extends Component {

    state = {
        review: '',
        isLike: 1,
        reviewError: false,
    };

    goBack = () => {
        this.props.dispatch(goBack());
    };

    sendFeedback = () => {
        if (this.state.review !== '') {
            addReview(this.props.vkId, this.state.review, this.state.isLike);
            this.props.dispatch(push('/complete-review'));
        } else {
            this.setState({ reviewError: true });
        }
    };

    onChangeReview = (e) => {
        if (e.target.value !== '') this.setState({ review: e.target.value, reviewError: false });
        else this.setState({ review: e.target.value });
    };

    onChangeIsLike = (e) => {
        this.setState({ isLike: e.target.value });
    };

    render() {
        return (
            <Panel id={ this.props.id } theme="white">
                <PanelHeader
                    left={
                        <HeaderButton onClick={ this.goBack }>
                            { platform() === IOS ? <Icon28ChevronBack className="black" /> : <Icon24Back className="black" /> }
                        </HeaderButton>
                    }
                >
                    <PanelHeaderContent>
                        Отзыв
                    </PanelHeaderContent>
                </PanelHeader>
                <FormLayout>
                    <FormLayoutGroup top="Нравится ли Вам сервис?">
                        <Radio name="radio" value={ 1 } defaultChecked onChange={ this.onChangeIsLike }>Да</Radio>
                        <Radio name="radio" value={ 0 } onChange={ this.onChangeIsLike }>Нет</Radio>
                    </FormLayoutGroup>
                    <FormLayoutGroup
                        top="Ваш отзыв"
                        bottom={ this.state.reviewError ? 'Необходимо ввести отзыв' : '' }
                    >
                        <Textarea
                            placeholder="Поделитесь с нами своими мыслями по улучшению сервиса..."
                            onChange={ this.onChangeReview }
                            status={ this.state.reviewError ? 'error' : 'default' }
                            className='mh-86'
                            maxLength={4096}
                        />
                    </FormLayoutGroup>
                </FormLayout>
                <FixedLayout vertical="bottom">
                    <Div>
                        <Button style={{ marginBottom: 8 }} size="xl" onClick={ this.sendFeedback }>Отправить</Button>
                        <Button size="xl" level="secondary" onClick={ this.goBack }>Отменить</Button>
                    </Div>
                </FixedLayout>
            </Panel>
        );
    }

}

function mapStateToProps(state) {
    return {
        selectedMenu: state.globals.selectedMenu,
        vkId: state.vk.vkUser.id,
    };
}

export default connect(mapStateToProps)(PanelFeedback);
