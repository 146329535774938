export const UPDATE_TIME = 1000;
export const REFRESH_HEIGHT_TIME = 20;
export const VK_COLOR = '#07F';

export const ROUTES_LIB = [
    {
        page: '/',
        panel: 'panelMain',
    },
    {
        page: '/link',
        panel: 'panelAll',
    },
    {
        page: '/wifi',
        panel: 'panelAll',
    },
    {
        page: '/contact',
        panel: 'panelAll',
    },
    {
        page: '/text',
        panel: 'panelAll',
    },
    {
        page: '/event',
        panel: 'panelAll',
    },
    {
        page: '/geo',
        panel: 'panelAll',
    },
    {
        page: '/complete',
        panel: 'panelComplete',
    },
    {
        page: '/complete-review',
        panel: 'panelCompleteReview',
    },
    {
        page: '/customize',
        panel: 'panelCustomize',
    },
    {
        page: '/feedback',
        panel: 'panelFeedback',
    },
    {
        page: '/info',
        panel: 'panelAbout',
    }
];
