import React, { Component } from 'react';
import { connect } from 'react-redux';
import {googleEvent, yandexEvent} from "../services/_functions";
import { setAdvancedIconSelected } from "../store/settings/actions";
import { setPopout } from "../store/globals/actions";
import PopoutUploadIcon from "./PopoutUploadIcon";


class BlockCustomizeIcon extends Component {

    handleIconOptionChange = (changeEvent) => {
        if (+changeEvent.target.value !== 4) this.props.dispatch(setAdvancedIconSelected(+changeEvent.target.value));

        switch (+changeEvent.target.value) {
            case 1:
                googleEvent('Edit', 'Icon', 'No_Icon');
                yandexEvent('edit_icon_no_icon');
                break;
            case 2:
                googleEvent('Edit', 'Icon', 'Blue_VK');
                yandexEvent('edit_icon_blue_vk');
                break;
            case 3:
                googleEvent('Edit', 'Icon', 'Black_VK');
                yandexEvent('edit_icon_black_vk');
                break;
        }
    };

    openModal = () => {
        this.props.dispatch(setPopout(<PopoutUploadIcon/>));
        googleEvent('Edit', 'Icon', 'Upload');
        yandexEvent('edit_icon_upload');
    };

    render() {
        return (
            <React.Fragment>
                <div className="group-form">
                    <div className="group-form__radio">
                        <div className="form-radio-icon">
                            <input
                                type="radio"
                                id="radio-icon-1"
                                name="radio-icon"
                                value="1"
                                checked={ this.props.iconSelected === 1 }
                                onChange={ this.handleIconOptionChange }
                            />
                            <label htmlFor="radio-icon-1">
                                <span className="form-radio-icon__img form-radio-icon__none"/>
                            </label>
                        </div>
                        <div className="form-radio-icon">
                            <input
                                type="radio"
                                id="radio-icon-2"
                                name="radio-icon"
                                value="2"
                                checked={ this.props.iconSelected === 2 }
                                onChange={ this.handleIconOptionChange }
                            />
                            <label htmlFor="radio-icon-2">
                                <span className="form-radio-icon__img form-radio-icon__vk"/>
                            </label>
                        </div>
                        <div className="form-radio-icon">
                            <input
                                type="radio"
                                id="radio-icon-3"
                                name="radio-icon"
                                value="3"
                                checked={ this.props.iconSelected === 3 }
                                onChange={ this.handleIconOptionChange }
                            />
                            <label htmlFor="radio-icon-3">
                                <span className="form-radio-icon__img form-radio-icon__vk-black"/>
                            </label>
                        </div>
                        <div className="form-radio-icon form-radio-icon__dash">
                            <div className="form-radio-icon_div-upload-icon" onClick={ this.openModal }/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        iconSelected: state.settings.advanced.iconSelected,
    };
};

export default connect(mapStateToProps)(BlockCustomizeIcon);