export const SET_PAGE                   = 'SET_PAGE';
export const SET_MODAL                  = 'SET_MODAL';
export const SET_COLOR_MODAL            = 'SET_COLOR_MODAL';
export const SET_SELECTED_URL           = 'SET_SELECTED_URL';
export const SET_SHOW_REVIEW_RESULT     = 'SET_SHOW_REVIEW_RESULT';
export const SET_SELECTED_MENU          = 'SET_SELECTED_MENU';
export const SET_POPOUT                 = 'SET_POPOUT';
export const SET_ACTIVE_MODAL           = 'SET_ACTIVE_MODAL';
export const SET_BLOCK_CHANGED          = 'SET_BLOCK_CHANGED';
export const SET_BLOCK_CHANGING         = 'SET_BLOCK_CHANGING';
export const RAND_QR_KEY                = 'RAND_QR_KEY';
export const SET_SAVE_AVAILABLE         = 'SET_SAVE_AVAILABLE';
export const SET_REVIEWED               = 'SET_REVIEWED';
export const SET_HISTORY               = 'SET_HISTORY';
export const DECREMENT_HISTORY               = 'DECREMENT_HISTORY';