import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {Avatar, Button, Snackbar} from '@vkontakte/vkui';
import {svgAsPngUri} from "save-svg-as-png";
import {googleEvent, urlToFile, urlToGoogleLink, yandexEvent} from "../services/_functions";
import {previewImage} from "../services/vk";
import {saveLink, sendImage} from "../services/axios";
import {setTextText} from "../store/settings/actions";
import {Icon16Cancel} from "@vkontakte/icons";
import doInputValidation from "../functions/doInputValidation";


class Footer extends Component {

    state = {
        isSaving: false,
        snackbar: null,
    };

    openCustomize = () => {
        this.props.dispatch(push('/customize'));
    };

    saveImg = async() => {
        const validation = this.props.dispatch(doInputValidation(this.props.mode));
        if (validation !== true) {
            this.setState({ snackbar: validation });
            return false;
        }

        if (!this.state.isSaving) {
            this.setState({ isSaving: true });
            const qr = document.getElementById("qr-box").children[0].children[0].children[0];
            svgAsPngUri(qr, {}).then(uri => {
                setTimeout(() => {
                    svgAsPngUri(qr, {}).then(uri => {
                        urlToFile(uri, 'hello.png', 'image/png')
                            .then(async (file) => {
                                const res = await sendImage(file);
                                if (res['result'] === 'success') this.props.dispatch(previewImage(res['image']));
                                this.setState({isSaving: false});

                                // if (!this.props.isReviewed) this.props.dispatch(push('/feedback'));
                                // else this.props.dispatch(push('/'));
                                // this.props.dispatch(setReviewed());
                                // setReview(this.props.vkId);
                                this.props.dispatch(push('/complete'));
                                this.props.dispatch(setTextText(''));

                                googleEvent('Download', 'png', urlToGoogleLink(this.props.selectedUrl));
                                yandexEvent('download_png_' + this.props.selectedUrl);
                            });
                    });
                }, 500);
            });
            if (this.props.selectedMenu === 'link') saveLink(this.props.vkId, this.props.linkUrl);
        }
    };

    render() {
        return (
            <>
                <div className={ "footer-btn" + (this.props.className ? ' ' + this.props.className : '') }>
                    <Button size="l" level="secondary" onClick={ this.openCustomize }>Кастомизация</Button>
                    <Button disabled = { !this.props.saveAvailable } size="l" onClick={ this.saveImg }>Скачать</Button>
                </div>

                {this.state.snackbar !== null && (
                    <Snackbar
                        onClose={() => this.setState({ snackbar: null })}
                        before={<Avatar size={24} style={{ background: 'var(--destructive)' }}><Icon16Cancel fill="#fff" width={14} height={14} /></Avatar>}
                    >
                        {this.state.snackbar}
                    </Snackbar>
                )}
            </>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        isShowReviewResult: state.globals.isShowReviewResult,
        saveAvailable: state.globals.saveAvailable,
        selectedUrl: state.globals.selectedUrl,
        vkId: state.vk.vkUser.id,
        isReviewed: state.globals.isReviewed,
        selectedMenu: state.globals.selectedMenu,
        linkUrl: state.settings.link.url,
    };
};

export default connect(mapStateToProps)(Footer);
