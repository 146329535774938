import React, { Component } from 'react';
import { connect } from 'react-redux';
import {push} from 'react-router-redux';
import { platform, IOS, ANDROID, List, Cell, ModalPageHeader, HeaderButton, ModalRoot, ModalPage } from '@vkontakte/vkui';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import {setActiveModal, setSelectedMenu} from "../store/globals/actions";
import {googleEvent, yandexEvent} from "../services/_functions";
import Typography from "./Typography";
import Div from "@vkontakte/vkui/dist/es6/components/Div/Div";


class Modals extends Component {

    componentDidMount() {
        window.onpopstate = this.onBackOrForwardButtonEvent;
    }

    componentWillUnmount() {
        window.removeEventListener('onpopstate', this.onBackOrForwardButtonEvent);
    }

    onBackOrForwardButtonEvent = () => {
        if (this.props.activeModal !== null) {
            this.closeModal();
        }
    };

    closeModal = () => {
        this.props.dispatch(setActiveModal(null));
    };

    openPage = (page) => {
        this.closeModal();
        this.props.dispatch(setSelectedMenu(page));
        this.props.dispatch(push('/' + page));
    };

    openLink = () => {
        this.openPage('link');
        googleEvent('Content type', 'Link', 'Menu');
        yandexEvent('content_type_link_menu');
    };

    openWiFi = () => {
        this.openPage('wifi');
        googleEvent('Content type', 'Wi-Fi', 'Menu');
        yandexEvent('content_type_wi-fi_menu');
    };

    openContact = () => {
        this.openPage('contact');
        googleEvent('Content type', 'Contact', 'Menu');
        yandexEvent('content_type_contact_menu');
    };

    openText = () => {
        this.openPage('text');
        googleEvent('Content type', 'Text', 'Menu');
        yandexEvent('content_type_text_menu');
    };

    openEvent = () => {
        this.openPage('event');
        googleEvent('Content type', 'Event', 'Menu');
        yandexEvent('content_type_event_menu');
    };

    openGeo = () => {
        this.openPage('geo');
        googleEvent('Content type', 'Geo', 'Menu');
        yandexEvent('content_type_geo_menu');
    };

    render() {
        return (
            <ModalRoot activeModal={ this.props.activeModal }>
                <ModalPage
                    id={ 'modal-menu' }
                    header={
                        <ModalPageHeader
                            left={platform() === ANDROID && <HeaderButton onClick={ this.closeModal }><Icon24Cancel /></HeaderButton>}
                            right={platform() === IOS && <HeaderButton onClick={ this.closeModal }><Icon24Dismiss /></HeaderButton>}
                        >
                            Тип QR-кода
                        </ModalPageHeader>
                    }
                    onClose={ this.closeModal }
                >
                    <List className="modal-cells">
                        <Cell
                            asideContent={ (this.props.selectedMenu === 'link' ? <Icon24Done/> : '') }
                            onClick={ this.openLink }
                        ><div className="cell-style"><div className="modal-cells__link"/>Ссылка</div></Cell>
                        <Cell
                            asideContent={ (this.props.selectedMenu === 'wifi' ? <Icon24Done/> : '') }
                            onClick={ this.openWiFi }
                        ><div className="cell-style"><div className="modal-cells__wifi"/>Настройки Wi-Fi</div></Cell>
                        <Cell
                            asideContent={ (this.props.selectedMenu === 'contact' ? <Icon24Done/> : '') }
                            onClick={ this.openContact }
                        ><div className="cell-style"><div className="modal-cells__contact"/>Визитка</div></Cell>
                        <Cell
                            asideContent={ (this.props.selectedMenu === 'text' ? <Icon24Done/> : '') }
                            onClick={ this.openText }
                        ><div className="cell-style"><div className="modal-cells__text"/>Текст</div></Cell>
                        <Cell
                            asideContent={ (this.props.selectedMenu === 'event' ? <Icon24Done/> : '') }
                            onClick={ this.openEvent }
                        ><div className="cell-style"><div className="modal-cells__event"/>Событие</div></Cell>
                        <Cell
                            asideContent={ (this.props.selectedMenu === 'geo' ? <Icon24Done/> : '') }
                            onClick={ this.openGeo }
                        ><div className="cell-style"><div className="modal-cells__geo"/>Геолокация</div></Cell>
                    </List>
                </ModalPage>
                <ModalPage
                    id={ 'modal-print' }
                    header={
                        <ModalPageHeader
                            left={platform() === ANDROID && <HeaderButton onClick={ this.closeModal }><Icon24Cancel /></HeaderButton>}
                            right={platform() === IOS && <HeaderButton onClick={ this.closeModal }><Icon24Dismiss /></HeaderButton>}
                        >
                            Типографии
                        </ModalPageHeader>
                    }
                    onClose={ this.closeModal }
                >
                    <Div>
                        <Typography
                            title="Типография ПМГ"
                            address="Москва, ул. Балтийская, д. 11 (м. Сокол)"
                            site="pmg.ru"
                            sale="Скидка 20% на любой заказ по промокоду «VK Business»"
                        />
                        <Typography
                            title="Креативная типография «Енот»"
                            address="Н.Новгород, ул. Мануфактурная, д. 14, офис 208/2"
                            site="enotnn.ru"
                            sale="Скидка 10% по промокоду «ВКонтакте»"
                        />
                        <Typography
                            title="Printsburg.ru"
                            address="Санкт-Петербург, пр. Науки, д. 19/2"
                            site="printsburg.ru"
                            sale="Скидка 10% от бонусной цены по промокоду «ДЫРОКОЛ»"
                            allAddresses="https://printsburg.ru/contacts/"
                        />
                        <Typography
                            title="МДМпринт"
                            address="Москва, Комсомольский проспект, 28"
                            site="mdmprint.ru"
                            sale="Скидка 10% по промокоду «QRVK20205»"
                            allAddresses="https://mdmprint.ru/contacts/#adress"
                        />
                        <Typography
                            title="Типография «А-цифра»"
                            address="Москва, ул. Новокузнецкая, д. 1, офис 43"
                            site="www.a-cifra.ru"
                            url="www.a-cifra.ru/regiony/"
                            sale="Скидки до 20% по промокоду «Скидка2020»"
                        />
                    </Div>
                </ModalPage>
            </ModalRoot>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeModal: state.globals.activeModal,
        selectedMenu: state.globals.selectedMenu,
    };
};

export default connect(mapStateToProps)(Modals);
