import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setColorModal, setPopout } from "../store/globals/actions";
import {
    setLookBgImageFull, setLookBgLoadedFull,
    setLookStyle3
} from "../store/settings/actions";
import { uploadImage } from "../services/_functions";
import { Alert } from '@vkontakte/vkui';


class PopoutUploadBackground extends Component {

    closePopout = () => {
        this.props.dispatch(setPopout(null));
    };

    uploadImage = (e) => {
        const successFunc = (result) => {
            this.props.dispatch(setLookBgImageFull(result));
            this.props.dispatch(setLookBgLoadedFull(true));
            this.props.dispatch(setLookStyle3(5));
            this.props.dispatch(setColorModal(false));
        };

        const failFunc = () => {
            this.closePopout();
        };

        this.props.dispatch(uploadImage(e, successFunc, failFunc, 'picture'));
    };

    clickSelect = () => {
        let file = document.getElementById('bg-modal-file');
        file.click();
    };

    render() {
        return (
            <Alert
                actionsLayout="vertical"
                actions={[{
                    title: 'Загрузить',
                    action: this.clickSelect
                },{
                    title: 'Отмена',
                    autoclose: true,
                    style: 'cancel'
                }]}
                onClose={ this.closePopout }
            >
                <h2>Загрузка фоновой картинки</h2>
                <p>Можно загрузить картинку в формате .png, .jpg или .svg, размером не&nbsp;более&nbsp;5&nbsp;Мб</p>
                <input className="hide-it" type='file' id='bg-modal-file' onChange={ (e) => this.uploadImage(e) } />
            </Alert>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(PopoutUploadBackground);