import React, { Component } from 'react';
import { connect } from "react-redux";
import { go } from 'react-router-redux';
import { Div, Panel, FixedLayout, Button } from '@vkontakte/vkui';


class PanelCompleteReview extends Component {

    openBack = () => {
        this.props.dispatch(go(-3));
    };

    render() {
        return (
            <Panel id={ this.props.id } className="complete" theme="white">
                <div className="title">
                    <div className="icon-check"/>
                    <p>Отзыв оставлен</p>
                </div>
                <FixedLayout vertical="bottom" className="footer">
                    <Div>
                        <Button size="xl" level="secondary" onClick={ this.openBack }>Вернуться к конструктору</Button>
                    </Div>
                </FixedLayout>
            </Panel>
        );
    }

}

function mapStateToProps(state) {
    return {
        selectedMenu: state.globals.selectedMenu,
    };
}

export default connect(mapStateToProps)(PanelCompleteReview);