import React, { Component } from 'react';
import { connect } from 'react-redux';
import {calcHeight, googleEvent, uploadImage, wordPad, yandexEvent} from "../services/_functions";
import {
    setAdvancedLink,
    setAdvancedTitle, setAdvancedTopIcon, setAdvancedTopIconLoaded,
    setAdvancedWithImageAndText
} from "../store/settings/actions";
import { Button, Switch, Input, FormLayout } from '@vkontakte/vkui';
import {REFRESH_HEIGHT_TIME} from "../services/_locals";


class BlockCustomizeAdvanced extends Component {

    state = {
        charsLeftTitle: 18 - this.props.title.length,
        charsLeftSubTitle: 30 - this.props.link.length,
    };

    handleOptionChange = (changeEvent) => {
        this.props.dispatch(setAdvancedWithImageAndText(changeEvent.target.checked));

        if (changeEvent.target.checked) {
            googleEvent('Edit', 'Avatar', 'On');
            yandexEvent('edit_avatar_on');
        } else {
            googleEvent('Edit', 'Avatar', 'Off');
            yandexEvent('edit_avatar_off');
        }

        setTimeout(() => calcHeight(), REFRESH_HEIGHT_TIME);
    };

    updateGoogleTimeoutTitle;
    updateGoogleTimeoutDesc;

    uploadImage = (e) => {
        const successFunc = async(result) => {
            await this.props.dispatch(setAdvancedTopIcon(result));
            await this.props.dispatch(setAdvancedTopIconLoaded(true));
        };

        const failFunc = () => {};

        this.props.dispatch(uploadImage(e, successFunc, failFunc, 'avatar'));
    };

    clickSelect = () => {
        let file = document.getElementById('top-icon-file');
        file.value = '';
        if (!/safari/i.test(navigator.userAgent)) {
            file.type = '';
            file.type = 'file';
        }
        file.click();
        googleEvent('Edit', 'Avatar', 'Upload');
        yandexEvent('edit_avatar_upload');
    };

    unloadImage = () => {
        this.props.dispatch(setAdvancedTopIcon(''));
        this.props.dispatch(setAdvancedTopIconLoaded(false));
    };

    changeTitleHandler = (e) => {
        this.props.dispatch(setAdvancedTitle(e.target.value));
        this.setState({ charsLeftTitle: 18 - e.target.value.length });

        clearTimeout(this.updateGoogleTimeoutTitle);
        this.updateGoogleTimeoutTitle = setTimeout(() => {
            googleEvent('Edit', 'Page_title', 'New');
            yandexEvent('edit_page_title_new');
        }, 2500);
    };

    changeLinkHandler = (e) => {
        this.props.dispatch(setAdvancedLink(e.target.value));
        this.setState({ charsLeftSubTitle: 30 - e.target.value.length });

        clearTimeout(this.updateGoogleTimeoutDesc);
        this.updateGoogleTimeoutDesc = setTimeout(() => {
            googleEvent('Edit', 'Page_link', 'New');
            yandexEvent('edit_page_link_new');
        }, 2500);
    };

    render() {
        let imgStyle = {};
        if (this.props.topIconUploaded !== '') imgStyle = { backgroundImage: ('url(' + this.props.topIconUploaded + ')'), backgroundSize: 'cover' };

        const img = <div className="form-add-img circle">
            <div className="form-add-img__name">Изображение</div>
            <div className="form-add-img__box">
                <div className="form-add-img__img" style={ imgStyle }/>
                <div className="form-add-img__info">
                    <div className="group-box">
                        <Button level="secondary" onClick={ this.clickSelect }>Загрузить</Button>
                        { this.props.isTopIconLoaded && <Button level="secondary" className="btn btn-gray__del" onClick={ this.unloadImage }/> }
                        <input className="hide-it" type='file' id='top-icon-file' onChange={ (e) => this.uploadImage(e) } />
                    </div>
                </div>
            </div>
        </div>;

        let leftTitle = 'Осталось ' + this.state.charsLeftTitle + wordPad(this.state.charsLeftTitle, ' символ', ' символа', ' символов');
        if  (this.state.charsLeftTitle === 0) leftTitle = 'Достигнута максимальная длина текста';
        let leftSubTitle = 'Осталось ' + this.state.charsLeftSubTitle + wordPad(this.state.charsLeftSubTitle, ' символ', ' символа', ' символов');
        if  (this.state.charsLeftSubTitle === 0) leftSubTitle = 'Достигнута максимальная длина текста';

        const imageAndText =
            <React.Fragment>
                { !(this.props.selectedType === 4 && this.props.bgImage !== '') && img }
                <FormLayout className="advanced-layout">
                    <Input top="Заголовок" bottom={ leftTitle } maxLength={ 18 } type="text" placeholder="Введите заголовок" onChange={ this.changeTitleHandler } value={ this.props.title } />
                    <Input top="Подзаголовок" bottom={ leftSubTitle } maxLength={ 30 } type="text" placeholder="Введите подзаголовок" onChange={ this.changeLinkHandler } value={ this.props.link }/>
                </FormLayout>
            </React.Fragment>;

        const imageAndTextFull =
            <React.Fragment>
                <div className="advanced-toggle">
                    <div>Блок с картинкой и названием</div>
                    <Switch  checked={ this.props.withImageAndText } onChange={ this.handleOptionChange }/>
                </div>
                { this.props.withImageAndText && imageAndText }
            </React.Fragment>
        ;

        return (
            <React.Fragment>
                { imageAndTextFull }
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        selectedType: state.settings.look.selectedType,
        withImageAndText: state.settings.advanced.withImageAndText,
        iconSelected: state.settings.advanced.iconSelected,
        title: state.settings.advanced.title,
        link: state.settings.advanced.link,
        topIconUploaded: state.settings.advanced.topIconUploaded,
        bgImage: state.settings.look.bgImage,
        isTopIconLoaded: state.settings.advanced.isTopIconLoaded,
    };
};

export default connect(mapStateToProps)(BlockCustomizeAdvanced);