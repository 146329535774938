import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPopout } from "../store/globals/actions";
import { Alert } from '@vkontakte/vkui';


class PopoutError extends Component {

    closePopout = () => {
        this.props.dispatch(setPopout(null));
    };

    render() {
        return (
            <Alert
                actionsLayout="vertical"
                actions={[{
                    title: 'ОК',
                    autoclose: true,
                    style: 'cancel'
                }]}
                onClose={ this.closePopout }
            >
                <h2>Ошибка</h2>
                <p>{ this.props.error }</p>
            </Alert>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(PopoutError);