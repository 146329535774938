import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setActiveModal} from "../store/globals/actions";
import {withPlatform} from "@vkontakte/vkui";


class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lastModalOpen: 0,
        };
    }


    openModal = () => {
        if (this.state.lastModalOpen + 500 < Date.now()) {
            this.setState({ lastModalOpen: Date.now() });
            this.props.dispatch(setActiveModal('modal-menu'));
        }
    };

    render() {
        const { platform } = this.props;

        return (
            <div className={`header-page header-page_${platform}`} onClick={ this.openModal }>
                <div className="header-page__title">{ this.props.title }</div>
                <div className="header-page__icon"/>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(withPlatform(Header));