import * as types from "./actionTypes";
import * as React from "react";
import {randNum} from "../../services/_functions";
import {ROUTES_LIB} from "../../services/_locals";

const initialState = {
    page: '',
    modal: '',
    colorModal: '',
    link: '',
    selectedUrl: '',
    isShowReviewResult: false,
    selectedMenu: '',
    popout: null,
    activeModal: null,
    isBlockChanging: false,
    qrKey: 1,
    saveAvailable: true,
    isReviewed: false,
    history: [],
};

export const globals = (state = initialState, action) => {

    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            const panel = ROUTES_LIB.find((path) => action.payload.pathname === path.page)?.panel;

            let history;
            if (panel) {
                history = state.history.includes(panel) ? state.history : [...state.history, panel];
            } else {
                history = state.history;
            }

            return {
                ...state,
                history: history
            }
        }

        case types.DECREMENT_HISTORY:
            return {
                ...state,
                history: [...state.history.slice(0, state.history.length - 1)],
            };

        case types.SET_HISTORY:
            return {
                ...state,
                history: [...state.history, action.panel],
            };

        case types.SET_PAGE:
            return {
                ...state,
                page: action.page,
            };

        case types.SET_MODAL:
            if (action.modal !== '') document.body.classList.add("modal-opened");
            else document.body.classList.remove("modal-opened");
            return {
                ...state,
                modal: action.modal,
            };

        case types.SET_COLOR_MODAL:
            return {
                ...state,
                colorModal: action.colorModal,
            };

        case types.SET_SELECTED_URL:
            return {
                ...state,
                selectedUrl: action.selectedUrl,
                isBlockChanging: true,
            };

        case types.SET_SHOW_REVIEW_RESULT:
            return {
                ...state,
                isShowReviewResult: action.isShowReviewResult,
            };

        case types.SET_SELECTED_MENU:
            return {
                ...state,
                selectedMenu: action.selectedMenu,
            };

        case types.SET_POPOUT:
            return {
                ...state,
                popout: action.popout,
            };

        case types.SET_ACTIVE_MODAL:
            return {
                ...state,
                activeModal: action.activeModal,
            };

        case types.SET_BLOCK_CHANGED:
            return {
                ...state,
                isBlockChanging: false,
            };

        case types.SET_BLOCK_CHANGING:
            return {
                ...state,
                isBlockChanging: true,
            };

        case types.RAND_QR_KEY:
            return {
                ...state,
                qrKey: randNum(),
            };

        case types.SET_SAVE_AVAILABLE:
            return {
                ...state,
                saveAvailable: action.saveAvailable,
            };

        case types.SET_REVIEWED:
            return {
                ...state,
                isReviewed: true,
            };

        default:
            return state;
    }
};