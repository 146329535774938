import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    setWifiName, setWifiPass, setWifiType
} from "../store/settings/actions";
import {UPDATE_TIME} from "../services/_locals";
import { Input, Radio, FormLayout, FormLayoutGroup } from '@vkontakte/vkui';
import {googleEvent, yandexEvent} from "../services/_functions";


class BlockWifi extends Component {

    state = {
        timeout: undefined,
    };

    updateGoogleTimeoutName;
    updateGoogleTimeoutPass;
    updateGoogleTimeoutType;

    changeNameHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);
        this.setState({ timeout: setTimeout(() => this.props.dispatch(setWifiName(val)), UPDATE_TIME) });

        clearTimeout(this.updateGoogleTimeoutName);
        this.updateGoogleTimeoutName = setTimeout(() => {
            googleEvent('Edit', 'Wi-Fi', 'Data_network');
            yandexEvent('edit_wifi_data_network');
        }, 2500);
    };

    changePassHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);
        this.setState({ timeout: setTimeout(() => this.props.dispatch(setWifiPass(val)), UPDATE_TIME) });

        clearTimeout(this.updateGoogleTimeoutPass);
        this.updateGoogleTimeoutPass = setTimeout(() => {
            googleEvent('Edit', 'Wi-Fi', 'Data_password');
            yandexEvent('edit_wifi_data_password');
        }, 2500);
    };

    changeWifiTypeHandler = (e) => {
        this.props.dispatch(setWifiType(+e.target.value));

        clearTimeout(this.updateGoogleTimeoutType);
        this.updateGoogleTimeoutType = setTimeout(() => {
            googleEvent('Edit', 'Wi-Fi', 'Data_secure');
            yandexEvent('edit_wifi_data_secure');
        }, 2500);
    };

    blurNameHandler = (e) => {
        clearTimeout(this.state.timeout);
        if (e.target.value !== this.props.name) this.props.dispatch(setWifiName(e.target.value));
    };

    blurPassHandler = (e) => {
        clearTimeout(this.state.timeout);
        if (e.target.value !== this.props.pass) this.props.dispatch(setWifiPass(e.target.value));
    };

    render() {
        return (
            <FormLayout className="white-layout">
                <FormLayoutGroup top="Имя сети">
                    <Input type="text" placeholder="Введите имя сети" onChange={ this.changeNameHandler } defaultValue={ this.props.name } onBlur={ this.blurNameHandler } />
                </FormLayoutGroup>
                <FormLayoutGroup top="Пароль">
                    <Input type="password" placeholder="Введите пароль" onChange={ this.changePassHandler } defaultValue={ this.props.pass } onBlur={ this.blurPassHandler } />
                </FormLayoutGroup>
                <FormLayoutGroup top="Тип шифрования">
                    <Radio id="type-encryption-1" name="radio-box" value="1" checked={ this.props.wifiType === 1 } onChange={ this.changeWifiTypeHandler }>WPA/WPA2</Radio>
                    <Radio id="type-encryption-2" name="radio-box" value="2" checked={ this.props.wifiType === 2 } onChange={ this.changeWifiTypeHandler }>WEP</Radio>
                    <Radio id="type-encryption-3" name="radio-box" value="3" checked={ this.props.wifiType === 3 } onChange={ this.changeWifiTypeHandler }>Без шифрования</Radio>
                </FormLayoutGroup>
            </FormLayout>
        );
    }

}

function mapStateToProps(state) {
    return {
        name: state.settings.wifi.name,
        pass: state.settings.wifi.pass,
        wifiType: state.settings.wifi.wifiType,
    };
}

export default connect(mapStateToProps)(BlockWifi);